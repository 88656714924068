<script setup>
import Button from '@/Components/Button/Button.vue';
import Title from '@/Components/Title/Title.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import Form from './Edit/Form.vue';
const route = inject('route');

const props = defineProps({
    faq: Object,
    categoryOptions: Object,
});

const deleteForm = useForm({});

const deleteSubmit = () => {
    deleteForm.delete(route('staff.faqs.destroy', props.faq.id));
};
</script>

<template>
    <StaffLayout :title="$t('Edit FAQ')">
        <Title :title="$t('Edit FAQ')">
            <Button
                danger
                :processing="deleteForm.processing"
                :recentlySuccessful="deleteForm.recentlySuccessful"
                @click="deleteSubmit"
                :text="$t('Delete')"
            ></Button>
        </Title>
        <Form :faq="faq" :categoryOptions="categoryOptions" />
    </StaffLayout>
</template>
